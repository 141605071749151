
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { typeChecker, formatter } from '@re/utilities';
import { PasswordInput, Notify } from 'vant';
import { useMutation, useMutationLoading } from '@vue/apollo-composable';

import { useCountDown } from '@/composable/countDown';
import { applyForm, token } from '@/composable/auth';
import router, { RouteRecord } from '@/router';
import { authMutation } from '@/apollo/mutations';
import { IdTokenVM } from '@/view-models/auth.vm';

export default defineComponent({
  components: {
    VanPasswordInput: PasswordInput,
  },
  setup() {
    const state: {
      code: string;
      isSent: boolean;
      isLoading: boolean;
      disabledSendBtn: boolean;
      formattedMobile: string;
    } = reactive({
      code: '',
      isLoading: useMutationLoading(),
      isSent: computed(() => !!applyForm.value.smsOtpId),
      disabledSendBtn: computed(() => !state.isSent || count.value > 0 || state.isLoading),
      formattedMobile: computed(() => formatter.mobile(applyForm.value.mobile || '', applyForm.value.callingCode)),
    });

    const { count, restart } = useCountDown(60, 1000);

    const { mutate: sendSmsOtpVerificationMutate } = useMutation<{ sendSmsOtpVerification: { smsOtpId: string } }>(
      authMutation.sendSmsOtpVerification,
    );
    const { mutate: verifySmsOtpVerificationMutate } = useMutation<{ verifySmsOtpVerification: IdTokenVM }>(
      authMutation.verifySmsOtpVerification,
    );

    // TIPS: 目前有沒有要開放外國人使用，所以固定國碼為 886
    applyForm.value.callingCode = '886';

    async function mobileValidator(value: string) {
      return typeChecker.isMobile(state.formattedMobile, applyForm.value.callingCode) ? true : '手機格式不正確';
    }

    async function sendSMS() {
      try {
        // FIXME: add type of data ???
        // @ts-ignore
        const { data } = await sendSmsOtpVerificationMutate({
          param: {
            applyFormId: applyForm.value.id,
            callingCode: applyForm.value.callingCode,
            mobile: state.formattedMobile,
          },
        });
        applyForm.value.smsOtpId = data?.sendSmsOtpVerification?.smsOtpId ?? '';

        restart();

        Notify({
          type: 'success',
          message: '簡訊已寄送',
        });
      } catch (err) {
        console.log(err);
      }
    }

    function handleSendSMSClick(e: MouseEvent) {
      sendSMS();
      return e;
    }

    async function onSubmit() {
      if (!state.isSent) {
        sendSMS();
        return;
      }

      try {
        // FIXME: add type of data ???
        // @ts-ignore
        const { data } = await verifySmsOtpVerificationMutate({
          param: {
            smsOtpId: applyForm.value.smsOtpId,
            code: state.code,
          },
        });

        if (data?.verifySmsOtpVerification) {
          token.value = data?.verifySmsOtpVerification.token;
          applyForm.value = data?.verifySmsOtpVerification.applyForm;
        }

        router.push({ name: RouteRecord.SignUpForm.name });
      } catch (err) {
        console.log(err);
      }
    }

    return {
      ...toRefs(state),
      count,
      applyForm,

      mobileValidator,
      restart,
      sendSMS,
      handleSendSMSClick,
      onSubmit,
    };
  },
});
